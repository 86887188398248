<template>
  <div>
    <Header></Header>
    <div class="content">
      <swiper :options="swiperOption" ref="mySwiper" v-if="carouselPic.length>0">
          <swiper-slide v-for="(item, index) in carouselPic" :key="index">
            <img :src="oss + item" class="img_qa" alt="" />
          </swiper-slide>
      </swiper>
      <!-- <img src="../assets/static/images/img_about2.png" class="img_qa" alt="" /> -->
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3 plr30">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">关于我们</a>
        </div>
      </div>
      <div class="personal about_us pt30 m1440 flex-box flex-col-start">
        <div class="right_box flex-grow-1">
          <div class="ptb30 fs26 fwb">关于我们</div>
          <div>
            <div v-html="about_us.companyDesc"></div>
            <!-- 杰森公司是专业生产销售绿色环保轻质墙体、吊顶等室内装饰建筑材料，提供系统解决方案的外商独资企业。
            杰森公司本着品牌、市场、引领的发展战略进入中国市场，秉承着“品质、技术、服务、共赢”的经营理念，以公益、环保、安全为己任，致力于为中国建材市场贡献优质、节能、环保的绿色建材。
            自2008年4月杰森嘉兴工厂投产以来，杰森在中国投资1亿美元，分别在嘉兴、惠州和长春建立了三大石膏板、轻钢龙骨及粉料生产基地。目前公司石膏板年生产能力近1.2亿平方米。伴随中国建筑市场和石膏板行业的快速发展，杰森公司还将继续追加投资，兴建新的生产基地，不断研发和推出新的系统产品。
            杰森石膏板现旗下品牌有：杰森、杰饰博、杰森 · 黄金板、杰森 ·
            霍巴特、杰豪、豪森等一系列品牌。 -->
          </div>
          <div class="pt68 pb50 fs26 fwb">发展历程</div>
          <div class="flex-box years flex-between">
            <img src="../assets/static/icon/icon_left.png" class="icon_left" @click="changeYear(yearIndex - 1)" alt="" />
            <div class="year_list flex-grow-1 flex-box flex-around">
              <div
                class="dot_box"
                :class="yearIndex == index ? 'active' : ''"
                @click="changeYear(index)"
                v-for="(item, index) in devList"
                :key="index"
              >
                <div class="dot"></div>
                <div class="year">{{ item.year }}</div>
              </div>
            </div>
            <img src="../assets/static/icon/icon_right.png" class="icon_left" @click="changeYear(yearIndex + 1)" alt="" />
          </div>
          <!--  -->
          <template v-for="(item, index) in devList">
            <div class="flex-box flex-col-start bottom_box" v-if="yearIndex == index" :key="index">
              <!-- <img :src="item.imgSrc" class="about_bottom" alt="" /> -->
              <div class="flex-grow-1 bottom_right fs20 col6">
                <div v-html="item.content"></div>
                <!-- <div v-for="(item, indexs) in item.honorLsit" :key="indexs">{{ items }}</div> -->
                <!-- <div v-for="(items, indexs) in item.honorLsit" :key="indexs">{{ items }}</div> -->
              </div>
            </div>
          </template>
          <!--  -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      oss: this.global.uploadOss,
      carouselPic:[],
      about_us: {},
      devList: [],
      yearIndex: 0,
    };
  },

  created() {
    this.get_detail()
  },

  methods:{

    get_detail() {
      let that = this;
      let s_data = {
					sortType: 'DESC',
					deviceType: '1',
				}
      that.$api.apiAboutUs(s_data).then((res) => {
        if (res.resCode == 0) {
          let data = res.root[0];
          if (data.milestones != '' && data.milestones != null) {
						let milestones_list = JSON.parse(data.milestones);
						if (milestones_list && milestones_list.length > 0) {
              that.devList = milestones_list
						}
          }
            //轮播图
					if (data.carouselPic != null && data.carouselPic != '') {
						that.carouselPic = data.carouselPic.split(",")
					}
          that.about_us = data;
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },


    changeYear(index){
      if(index >= this.devList.length){
        index = this.devList.length - 1
      }
      this.yearIndex = index;
    }
  }
};
</script>

<style scoped>
.swiper-container{
  height: 40vh;
}

.swiper-container img{
  object-fit: cover;
  -o-object-fit: cover;
  height: 100%;
}
</style>
